import { Component } from "react";
import "./contactMe.css";
import { SiLinkedin } from "react-icons/si";
import EmailButton from "./emailButton";

class ContactMe extends Component {
  state = {
    emailInput: "",
    message: "",
    isEmailValid: true,
    submitMessage: "Thank You for contacting",
    showMessage: false,
    showErrorMessage: false,
    errorMessage: "Please fill all the details",
  };

  onSubmitForm = async (event) => {
    event.preventDefault();
    const { emailInput, message } = this.state;
    if (emailInput === "" || message === "") {
      this.setState({
        showErrorMessage: true,
      });
      return;
    }

    const url = `${process.env.REACT_APP_REQUEST_URL}/sendMail`;

    const data = {
      senderMail: btoa(emailInput),
      message: btoa(message),
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, options);
    if (response.status === 200) {
      this.setState({ showMessage: true, emailInput: "", message: "" });
    } else {
      console.log(response);
    }
  };

  onEnterEmailAddress = (event) => {
    this.setState({ emailInput: event.target.value, showMessage: false });
  };

  onEnterMessage = (event) => {
    this.setState({ message: event.target.value });
  };

  render() {
    const {
      emailInput,
      message,
      submitMessage,
      showMessage,
      showErrorMessage,
      errorMessage,
    } = this.state;
    const mailto =
      screen.width < 400
        ? process.env.REACT_APP_MAIL_TO_MOBILE
        : process.env.REACT_APP_MAIL_LINK;
    return (
      <div className="contact-me-container">
        <h1>ContactMe</h1>
        <form
          onSubmit={this.onSubmitForm}
          className="form-container"
          data-netlify="true"
        >
          <div className="input-field">
            <label>
              Email :{" "}
              <input
                type="email"
                placeholder="Enter your Email"
                value={emailInput}
                onChange={this.onEnterEmailAddress}
              />
            </label>
          </div>
          <div className="input-field">
            <label>
              Message:{" "}
              <textarea
                placeholder="Enter Message"
                value={message}
                onChange={this.onEnterMessage}
              ></textarea>
            </label>
          </div>
          <button className="submit-button" type="submit">
            Submit
          </button>
          {showMessage ? <p className="submit-message">{submitMessage}</p> : ""}
          {showErrorMessage ? (
            <p className="error-message">{errorMessage}</p>
          ) : (
            ""
          )}
        </form>
        <div className="my-contact-links">
          <EmailButton label="Jnanatejaswi" mailto={mailto} />
          <a
            rel="noreferrer"
            href={process.env.REACT_APP_LINKEDIN_LINK}
            target="_blank"
          >
            <SiLinkedin className="link-icon" />
            Jnana Tejaswi
          </a>
        </div>
      </div>
    );
  }
}

export default ContactMe;
