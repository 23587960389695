import { Component } from "react";
import "./aboutMe.css";

class AboutMe extends Component {
  render() {
    return (
      <div className="about-me">
        <h1>About Me</h1>
        <p>
          Greetings! I{"'"}m{" "}
          <span className="about-me-name">Gudapati Jnana Tejaswi</span>, a
          passionate individual driven by curiosity and a in pursuit of
          knowledge. My educational journey about computers and softwares has
          been enriching, with a foundation laid at NxtWave CCBP and further
          knowledge through self-learning from online resources.
          <br />
          <br />
          As a self-learner, I{"'"}ve embraced the journy and uses of
          technology, constantly seeking out new challenges and opportunities to
          grow. My expertise spans across various domains, with a particular
          focus on software development and innovative problem-solving.
          <br />
          <br />
          In my personal projects, I{"'"}ve had created a TODO web application,
          offering users a seamless organizational tool, and developing a
          captivating Personal Portfolio, showcasing my skills and
          accomplishments and many more to done.
          <br />
          <br />
          My professional journey started as an intern at Probe Information
          Services Pvt Ltd(Probe 42), where I{"'"}ve worked on some real-world
          projects that have sharpened my skills and expanded my knowledge.
          <br />
          <br />
          From decoding captchas using OpenCV to extracting question and answer
          pairs from documents using LayoutLMv3, and harnessing the power of
          OpenAI for document text extraction, I{"'"}ve tackled complex
          challenges head-on, delivering impactful solutions with precision and
          creativity.
          <br />
          <br />
          Driven by a passion for innovation and a desire to make a meaningful
          impact, I am eager to continue pushing the boundaries of technology
          and contributing to the ever-evolving world of software development.
        </p>
      </div>
    );
  }
}

export default AboutMe;
