export const SkillElement = (props) => {
  const { skillName } = props;
  const { name, level } = skillName;

  return (
    <div className="each-skill">
      <h1 className="mobile-skill-name">{name}</h1>
      <p className="level">{level}</p>
    </div>
  );
};
