import { Component } from "react";
import "./skills.js";
import ForMobile from "./forMobile/forMobile.js";
import ForDesktop from "./forDesktop/forDesktop.js";

class Skills extends Component {
  render() {
    return (
      <>
        <div className="skills-page">
          <div className="desktop">
            <ForDesktop />
          </div>
          <div className="mobile">
            <ForMobile />
          </div>
        </div>
      </>
    );
  }
}

export default Skills;
