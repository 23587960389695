import { Component } from "react";
import { ProjectView } from "./projectView.js";
import "./projects.js";

const projectsList = [
  {
    id: "1",
    name: "Todo",
    image: "todo",
    description:
      "This is a simple TODO website created using HTML, CSS, and JavaScript. It allows users to manage tasks effectively, including adding, marking completion, editing, and deleting tasks.",
    githubLink: "https://github.com/Jnana5679/todo-app.git",
    demoLink: "https://jnana5679.github.io/todo-app/",
  },
  {
    id: "2",
    name: "Portfolio",
    image: "portfolio",
    description:
      "Portfolio website built with ReactJS! Explore my projects, skills, and experiences showcased in a sleek, responsive design. With intuitive navigation and interactive elements, you'll discover my passion for web development and design.",
    githubLink: "https://github.com/Jnana5679/personal-portfolio.git",
    demoLink: "https://jnanatejaswi.info",
  },
];

class Projects extends Component {
  renderProjects = () => {
    return (
      <>
        <div className="projects-container desktop-swiper">
          <swiper-container
            slides-per-view="auto"
            space-between="100"
            mousewheel="true"
            effect="coverflow"
            grab-cursor="true"
            centered-slides="true"
            coverflow-effect-rotate="0"
            coverflow-effect-stretch="0"
            coverflow-effect-depth="100"
            create-shadow="true"
          >
            {projectsList.map((eachProject) => {
              return (
                <ProjectView
                  key={eachProject.id}
                  projectDetails={eachProject}
                />
              );
            })}
          </swiper-container>
        </div>
        <div className="projects-container mobile-swiper">
          <swiper-container slides-per-view="auto" effect="coverflow">
            {projectsList.map((eachProject) => {
              return (
                <ProjectView
                  key={eachProject.id}
                  projectDetails={eachProject}
                />
              );
            })}
          </swiper-container>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="projects-page">
        <h1>Projects</h1>
        {this.renderProjects()}
      </div>
    );
  }
}

export default Projects;
