import resume from "../../assets/resume.pdf";

const resumeDownloadButton = () => {
  const onButtonClick = () => {
    fetch(resume).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Jnana Tejaswi.pdf";
        alink.click();
      });
    });
  };

  return (
    <button className="resume button" onClick={onButtonClick}>
      Resume
    </button>
  );
};

export default resumeDownloadButton;
