import { Component } from "react";
import { Link } from "react-scroll";
import ResumeDownloadButton from "./resumeDownloadButton";
import "./home.css";

class HomePage extends Component {
  render() {
    return (
      <div id="home" className="home-page-container">
        <div className="image-container"></div>
        <div className="info-container">
          <h3>Hello Iam</h3>
          <h1 className="name">Teja Gudapati</h1>
          <h1 className="profession">
            Software <span className="developer-text">Developer</span>
          </h1>
          <div className="buttons-container">
            <ResumeDownloadButton />
            <button className="contact-me button">
              <Link to="contact-me" smooth duration={500}>
                Contact Me
              </Link>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
