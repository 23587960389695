import { Link } from "react-router-dom";
import { SiGmail } from "react-icons/si";

const emailButton = (props) => {
  const { mailto, label } = props;
  return (
    <Link to={mailto} target="_blank">
      <SiGmail className="link-icon" />
      {label}
    </Link>
  );
};

export default emailButton;
