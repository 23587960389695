import { Link } from "react-scroll";

export const PageLinks = (props) => {
  const { linkDetails, onClickLink } = props;
  const { id, name } = linkDetails;

  return (
    <Link className="link" to={id} smooth duration={500} onClick={onClickLink}>
      {name}
    </Link>
  );
};
