import { Component } from "react";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { Link } from "react-scroll";
import { PageLinks } from "./pageLinks.js";
import "./navigationBar.css";

const linksList = [
  { id: "about", name: "About Me" },
  { id: "skills", name: "Skills" },
  { id: "projects", name: "Projects" },
  { id: "contact-me", name: "Contact Me" },
];

class NavigationBar extends Component {
  state = {
    hamburgerSelected: false,
    showSideBar: false,
    listLinks: linksList,
  };

  onClickHamburger = () => {
    this.setState((prevState) => ({
      hamburgerSelected: !prevState.hamburgerSelected,
      showSideBar: !prevState.showSideBar,
    }));
  };

  onClickSideBarLink = () => {
    this.onClickHamburger();
  };

  render() {
    const { hamburgerSelected, showSideBar, listLinks } = this.state;
    return (
      <nav className="navigation-bar">
        <Link className="profile-button" to="/" smooth duration={500}>
          <div className="dot"></div>
          Jnana Tejaswi
        </Link>
        <button className="profile-button">
          <Link to="/" smooth duration={500}>
            JT
          </Link>
        </button>
        <div className="links">
          {listLinks.map((eachLink) => {
            return <PageLinks key={eachLink.id} linkDetails={eachLink} />;
          })}
        </div>
        {!hamburgerSelected && (
          <button
            className="hamburger-icon-button"
            onClick={this.onClickHamburger}
          >
            <RxHamburgerMenu />
          </button>
        )}
        {hamburgerSelected && (
          <button
            className="hamburger-icon-button"
            onClick={this.onClickHamburger}
          >
            <RxCross1 />
          </button>
        )}
        {showSideBar && (
          <div className="collapsable-side-bar">
            {linksList.map((eachLink) => {
              return (
                <PageLinks
                  key={eachLink.id}
                  linkDetails={eachLink}
                  onClickLink={this.onClickSideBarLink}
                />
              );
            })}
          </div>
        )}
      </nav>
    );
  }
}

export default NavigationBar;
