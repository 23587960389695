import { FaHtml5, FaJs, FaPython, FaCss3, FaGitAlt } from "react-icons/fa";
import { DiMongodb } from "react-icons/di";
import { SiSqlite } from "react-icons/si";
import { FaAngular, FaNodeJs, FaReact } from "react-icons/fa";
import { BiLogoTypescript } from "react-icons/bi";
import { SiExpress } from "react-icons/si";

export const DesktopSkillElement = (props) => {
  const { skillDetails } = props;
  const { name, level } = skillDetails;

  const getSkillIcon = (name) => {
    switch (name) {
      case "Python":
        return <FaPython className="icon" />;
      case "HTML":
        return <FaHtml5 className="icon" />;
      case "JavaScript":
        return <FaJs className="icon" />;
      case "MongoDB":
        return <DiMongodb className="icon" />;
      case "SQLite":
        return <SiSqlite className="icon" />;
      case "Node JS":
        return <FaNodeJs className="icon" />;
      case "React JS":
        return <FaReact className="icon" />;
      case "Angular JS":
        return <FaAngular className="icon" />;
      case "TypeScript":
        return <BiLogoTypescript className="icon" />;
      case "CSS":
        return <FaCss3 className="icon" />;
      case "Express JS":
        return <SiExpress className="icon" />;
      case "Git":
        return <FaGitAlt className="icon" />;
    }
  };

  return (
    <div className="skill">
      {getSkillIcon(name)}
      <button>{name}</button>
      <p>{level}</p>
    </div>
  );
};
