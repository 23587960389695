import { Component } from "react";
import "../skills.css";
import { SkillElement } from "./skillElement";

const frontEndSkills = [
  { name: "HTML", level: "Intermediate" },
  { name: "React JS", level: "Intermediate" },
  { name: "Angular JS", level: "Basic" },
  { name: "TypeScript", level: "Basic" },
  { name: "CSS", level: "Intermediate" },
];

const backEndSkills = [
  { name: "Node JS", level: "Intermediate" },
  { name: "Python", level: "Intermediate" },
  { name: "JavaScript", level: "Intermediate" },
  { name: "Express JS", level: "Intermediate" },
  { name: "Git", level: "Intermediate" },
];

const dataBase = [
  { name: "MongoDB", level: "Intermediate" },
  { name: "SQLite", level: "Basic" },
];

class ForMobile extends Component {
  render() {
    return (
      <div>
        <div className="mobile-skill-container">
          <h1 className="heading">Frontend</h1>
          <div className="mobile-skills">
            {frontEndSkills.map((eachSkill) => {
              return (
                <SkillElement key={eachSkill.name} skillName={eachSkill} />
              );
            })}
          </div>
        </div>
        <div className="mobile-skill-container">
          <h1 className="heading">Backend</h1>
          <div className="mobile-skills">
            {backEndSkills.map((eachSkill) => {
              return (
                <SkillElement key={eachSkill.name} skillName={eachSkill} />
              );
            })}
          </div>
        </div>
        <div className="mobile-skill-container">
          <h1 className="heading">database</h1>
          <div className="mobile-skills">
            {dataBase.map((eachSkill) => {
              return (
                <SkillElement key={eachSkill.name} skillName={eachSkill} />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ForMobile;
