import { Component } from "react";
import "../skills.css";
import { DesktopSkillElement } from "./skill";

const frontEndSkills = [
  { name: "HTML", level: "Intermediate" },
  { name: "React JS", level: "Intermediate" },
  { name: "Angular JS", level: "Basic" },
  { name: "TypeScript", level: "Basic" },
  { name: "CSS", level: "Intermediate" },
];

const backEndSkills = [
  { name: "Node JS", level: "Intermediate" },
  { name: "Python", level: "Intermediate" },
  { name: "JavaScript", level: "Intermediate" },
  { name: "Express JS", level: "Intermediate" },
  { name: "Git", level: "Intermediate" },
];

const dataBase = [
  { name: "MongoDB", level: "Intermediate" },
  { name: "SQLite", level: "Basic" },
];

export default class ForDesktop extends Component {
  render() {
    return (
      <div className="skills-page-container">
        <h1>Frontend</h1>
        <div className="skills-container">
          {frontEndSkills.map((eachSkill) => {
            return (
              <DesktopSkillElement
                key={eachSkill.name}
                skillDetails={eachSkill}
              />
            );
          })}
        </div>
        <div>
          <h1>Backend</h1>
          <div className="skills-container">
            {backEndSkills.map((eachSkill) => {
              return (
                <DesktopSkillElement
                  key={eachSkill.name}
                  skillDetails={eachSkill}
                />
              );
            })}
          </div>
        </div>
        <div>
          <h1>DataBase</h1>
          <div className="skills-container">
            {dataBase.map((eachSkill) => {
              return (
                <DesktopSkillElement
                  key={eachSkill.name}
                  skillDetails={eachSkill}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
