import "./projects.css";
import todo from "../../assets/projects-images/todo/no-task-added.png";
import portfolio from "../../assets/projects-images/portfolio/portfolio.png";

export const ProjectView = (props) => {
  const { projectDetails } = props;
  const { name, image, description, githubLink, demoLink } = projectDetails;

  const getImage = () => {
    switch (image) {
      case "todo":
        return todo;
      case "portfolio":
        return portfolio;
    }
  };

  return (
    <swiper-slide>
      <img
        className="project-image"
        src={getImage()}
        alt="https://www.freepik.com/free-vector/landing-page-concept_2221364.htm#query=project%20demo&position=1&from_view=keyword&track=ais&uuid=64a91509-a7d0-4921-9976-a04a4cad8741"
      />
      <h2 className="project-name">{name}</h2>
      <p className="project-description">{description}</p>
      <div className="project-buttons-container">
        <a href={githubLink} target="_blank" rel="noreferrer">
          <button className="project-git-button" type="button">
            Git
          </button>
        </a>
        <a href={demoLink} target="_blank" rel="noreferrer">
          <button type="button" className="project-demo-button">
            Demo
          </button>
        </a>
      </div>
    </swiper-slide>
  );
};
