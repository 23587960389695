import "./App.css";
import NavigationBar from "./components/navigationBar/navigationBar";
import HomePage from "./components/homePage/home";
import AboutMe from "./components/aboutMe/aboutMe";
import Skills from "./components/skills/skills";
import Projects from "./components/projects/projects";
import ContactMe from "./components/contactMe/contactMe";

function App() {
  return (
    <div>
      <NavigationBar />
      <div id="/" className="page">
        <HomePage />
      </div>
      <div id="about" className="page">
        <AboutMe />
      </div>
      <div id="skills" className="page">
        <Skills />
      </div>
      <div id="projects" className="page">
        <Projects />
      </div>
      <div id="contact-me" className="page">
        <ContactMe />
      </div>
    </div>
  );
}

export default App;
